<template>
  <v-container fluid>
    <v-card>
      <v-card-title
        :class="[this.$vuetify.theme.dark? 'dark-bg' : '']"
        class="
          card-header card-header-title
          font-size-lg
          text-capitalize
          font-weight-normal
        "
      >
        {{ $t("analytics.lang_salesPerPayementType") }}
      </v-card-title>

      <v-card-text class="mt-6">
        <v-row align="center">
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <BaseDateInput
              v-model="startDate"
              type="date"
              :label="$t('generic.lang_from')"
              dense
              outlined
              hide-details
            />
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <BaseDateInput
              v-model="endDate"
              type="date"
              :label="$t('generic.lang_till')"
              dense
              outlined
              hide-details
            />
          </v-col>

          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <v-btn
              :disabled="this.loading || !startDate || !endDate"
              :loading="this.loading"
              @click="getSalesData"
              block
              class="bg-primary text-light mx-auto"
            >
              <v-icon class="ma-1">remove_red_eye</v-icon>
              {{ this.$t("generic.lang_display") }}
            </v-btn>
          </v-col>

          <v-col cols="12" class="pa-0 ma-0">
            <v-divider class="pa-0 ma-0" />
          </v-col>

          <v-col cols="12" class="mx-auto">
            <v-card v-if="showTopChart">
              <v-card-text>
                <apexchart
                  type="donut"
                  height="700"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card v-if="showTable">
              <v-card-text class="pa-0">
                <v-data-table
                  :items="dataToShow"
                  :headers="dataTableHeaders"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                >
                  <template v-slot:item.total="{item}">
                    {{ item.total|currency }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "@/config";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import { Events } from "@/plugins/events";
import BaseDateInput from "@/components/common/BaseDateInput.vue";

var total = null;
var self = null;

export default {
  name: "SalesPerPayementTypeStatisticsComponent",
  components: {
    apexchart: VueApexCharts,
    BaseDateInput
  },
  data() {
    return {
      ENDPOINTS,
      startDate: null,
      endDate: null,
      loading: false,
      showTopChart: false,
      series: [],
      chartOptions: {
        theme: {
          mode: this.$vuetify.theme.dark? 'dark' : 'light', 
        },
        labels: [],
        colors: [],
        plotOptions: {
          pie: {
            size: 30,
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: false,
                  label: this.$t("generic.lang_total"),
                  formatter: function (val) {
                    return (
                        self.$options.filters.currency(val.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0)) + " (100%)"
                    );
                  },
                },
                value: {
                  show: true,
                  fontSize: "1rem",
                  formatter: function (val) {
                    let percent = (val * 100) / total;
                    return self.$options.filters.currency(val) + "(" + percent.toFixed(2) + "%)";
                  },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              legend: {
                position: "bottom",
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      value: {
                        fontSize: "0.8rem",
                        formatter: function (val) {
                          let percent = (val * 100) / total;
                          return self.$options.filters.currency(val) + " (" + percent.toFixed(2) + "%)";
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        ],
      },
      sortBy: "payementType",
      sortDesc: true,
      showTable: false,
      dataToShow: [],
      dataTableHeaders: [
        {
          text: this.$t("accounting.lang_PaymentMethod"),
          value: "payementType",
        },
        {
          text: this.$t("generic.lang_total"),
          value: "total",
        },
        {
          text: this.$t("erp.lang_percent"),
          value: "percent",
        },
      ],
    };
  },
  watch: {
    startDate(val) {
      if (
        val != "" &&
        val != null &&
        this.endDate != "" &&
        this.endDate != null &&
        new Date(val).getTime() >= new Date(this.endDate).getTime()
      ) {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t("generic.lang_select_date_before") + " : " + this.endDate,
        });
        this.startDate = null;
      }
    },
    endDate(val) {
      if (
        val != "" &&
        val != null &&
        this.startDate != "" &&
        this.startDate != null &&
        new Date(val).getTime() <= new Date(this.startDate).getTime()
      ) {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t("generic.lang_select_date_after") + " : " + this.startDate,
        });
        this.endDate = null;
      }
    },
  },
  mounted() {
    total = this;
    self=this;
  },
  methods: {
    prepareDataTable(data) {
      this.showTable = false;
      let dataToRender = [],
        row = [];

      total = data.reduce(
        (previousValue, currentValue) =>
          currentValue[0] != "total"
            ? previousValue + currentValue[1]
            : previousValue,
        0
      );

      data.forEach((el, i) => {
        row = {};

        if (el[0] != "total") {
          let percent = (el[1] * 100) / total;
          row["payementType"] = el[0];
          row["total"] = Number(el[1]);
          row["percent"] = percent.toFixed(2) + " %";

          dataToRender.push(row);
        }
      });

      this.dataToShow = dataToRender;
      this.showTable = true;
    },
    async getSalesData() {
      this.loading = true;
      this.showTopChart = false;

      this.chartOptions.labels = [];
      this.chartOptions.colors = [];
      this.series = [];

      const params = {
        start_date:
          this.startDate == "" || this.startDate == null
            ? null
            : moment(this.startDate).startOf("day").unix(),
        end_date:
          this.endDate == "" || this.endDate == null
            ? null
            : moment(this.endDate).endOf("day").unix(),
      };

      var config = {
        method: "post",
        url: `${ENDPOINTS.DATATABLES.ANALYTICS.UMSATZANALYSEN.SALESPERPAYEMENT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(params),
      };

      await this.axios(config)
        .then((res) => {
          if (res.status == 200) {
            var data = Object.entries(res.data);

            if (data.length === 0) {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_noDataToDisplay"),
                color: "warning",
              });
              return;
            }

            this.prepareDataTable(data);

            for (let i = 0; i < data.length; i++) {
              let color = `rgb(
                ${Math.floor(Math.random() * 255)}, 
                ${Math.floor(Math.random() * 255)}, 
                ${Math.floor(Math.random() * 255)}
              )`;

              if (data[i][0] != "total") {
                this.chartOptions.labels.push(data[i][0]);
                this.chartOptions.colors.push(color);
                this.series.push(data[i][1]);
              }
            }

            this.showTopChart = true;

            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_dataLoading"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.msg || this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
